import {
  getUploadManipulateUrl,
  getUploadUrl
} from '../src/apis/upload_service_client';
import {
  customPreviewMaxDimension,
  mmsImagesProduction,
  mmsImagesStaging
} from './constants/mmsImagesConstants';
import allowSettingCustomDesignPreview from './previewUpload';

const filecache = {};

//TODO extract into .env files / env.js files
export const mmsImageBase = () =>
  isProd() ? mmsImagesProduction : mmsImagesStaging;

const DEFAULT_IMAGE_OPTIONS = {
  placeMax: 1,
  placeMaxPct: 0.8,
  placeUseProduct: 1,
  placeUseView: 'front'
};

export function getColorImage(
  colorId,
  hit,
  fingerprint = '',
  options = {},
  noLaydown,
  iframer,
  isLabImageEligible = true,
  isSiteWideSearch = false,
  customDesignPreview = null,
  setDefaultDesignPreview = () => {},
  defaultDesignPreview = null,
  designPreviewEnabledCategoryIds = []
) {
  const previewUploadEnabled = allowSettingCustomDesignPreview(
    iframer,
    designPreviewEnabledCategoryIds,
    hit.category_id || hit.primary_category_id
  );
  options.size = options.size || 'medium';
  const urlCid = getRequestDesign();
  options.design = urlCid || options.design;
  if (!isLabImageEligible && !noLaydown) return hit.style_card_image_url;

  // remove design when iframer, except when urlCid (cid from querystring) has a value
  if (!options.design || (iframer && !urlCid) || (isSiteWideSearch && !urlCid))
    delete options.design;

  // revise MIA designs
  if (options.design === 'dmh0-00cp-07tk') options.design = 'nmp0-00cg-4gv0';
  if (options.design === 'dmh0-00cq-f8nu') options.design = 'djn0-00by-wu36';
  if (options.design === 'dmh0-00cn-jw1r') options.design = 'djn0-00by-wu36';
  if (options.design === 'bpb0-00cj-ht9w') options.design = 'nmp0-00cg-4gv0';
  if (options.design === 'dmh0-00cp-wfy3') options.design = 'njg0-00cn-5005';
  if (options.design === 'gbj0-00cs-9g8e') options.design = 'dmh0-00cs-xnmx';

  if (previewUploadEnabled) {
    if (
      (!!options.design &&
        !(options.design?.slice(0, 4) === 'http') &&
        defaultDesignPreview !== options.design) ||
      options.design === null
    ) {
      options.originalDesign = options.design;
    } else {
      options.design = options.originalDesign;
    }

    // Override design with encoded customDesignPreview url if present && the primary category ID is in the list of categories that support design preview
    if (customDesignPreview) {
      options.design = customDesignPreview?.url;
    }
  }

  const cachekey = `${colorId}-${JSON.stringify(options)}`;
  if (filecache[cachekey]) return filecache[cachekey];

  if (!options.digest) {
    options.digest = '000000028';
  }
  const url = new URL(
    `${mmsImageBase()}/${fingerprint}colors/${colorId}/views/alt/front_${options.size}${options.extended ? '_extended' : ''}.png`
  );
  delete options.size;
  delete options.extended;

  // Persist originalDesign in options but don't pass it as a URL param
  const {originalDesign: _, ...urlSearchParams} = options;
  const params = new URLSearchParams(urlSearchParams);

  params.sort();
  url.search = params.toString();
  ensureDecoViz(hit, url);
  if (previewUploadEnabled)
    setCustomDesignPreviewUrlParams(customDesignPreview, url, hit.color_limit);
  filecache[cachekey] = url.toString();
  return url.toString();
}

export const mmsImageFingerprint = (hit) => {
  let og = '';
  if (hit?.imageFingerprint) {
    return hit.imageFingerprint;
  }
  if (hit?.style_card_image_url) {
    og = hit.style_card_image_url;
  }
  if (hit.colors && hit.colors.length) {
    og = hit.colors[0].image_url || '';
  }
  const pos = og.indexOf('/images/catalog');
  if (pos > 2) {
    const segments = og.substring(pos + 10).split('/');
    if (segments[1].length < 10) return '';
    return `${segments[1]}/`;
  }
  return '';
};

export const getIxParams = (hit, categoryId, fallbackUrl) => {
  const ixParamsFilter = ([key, _val]) => key.startsWith('ix', 0);
  let catImageData = false;
  let ixParams = false;
  if (hit && hit.category_images) {
    catImageData = hit.category_images.find((cat) => cat.id == categoryId);
    if (catImageData && catImageData.ix_params) {
      ixParams = catImageData.ix_params;
    }
  }
  if (!ixParams && fallbackUrl) {
    ixParams = filterUrlSearchParams(fallbackUrl, ixParamsFilter);
  }
  return ixParams || {};
};

export const getYdhParams = (fallbackUrl) => {
  const ydhParamsFilter = ([key, _val]) => !key.startsWith('ix', 0);
  return filterUrlSearchParams(fallbackUrl, ydhParamsFilter);
};

// Parses UploadService response and extracts url for the temporary user upload
export const createCustomDesignPreviewUploadUrl = (
  uploadServiceResponseData = {}
) => {
  let uploadUrl = `${getUploadUrl(uploadServiceResponseData.url)}`;

  // If the user upload has a background we want to make it transparent via UploadService's manipulate endpoint.
  // The only color that's going to be marked as transparent is the one that matches the background. We need to list
  // all of them as otherwise, colors present in the image and not listed in edit_colors params will be removed.
  if (uploadServiceResponseData?.background) {
    let colorTriplets = [];
    Object.entries(uploadServiceResponseData.colors).map(
      ([color, colorCIMatch]) => {
        colorTriplets.push(
          `${hexColorDigits(color)},${hexColorDigits(colorCIMatch)},${color === uploadServiceResponseData.background ? 'transparent' : hexColorDigits(colorCIMatch)}`
        );
      }
    );
    const editColorParam = colorTriplets.join(',');
    const widthParam = uploadServiceResponseData.dimensions.width;
    const params = `?edit_colors=${editColorParam}&width=${widthParam}&max_dimension=${customPreviewMaxDimension}`;
    uploadUrl = getUploadManipulateUrl(
      uploadServiceResponseData.url.replace('/uploads/', ''),
      params
    );
  }

  return uploadUrl;
};

export function overrideUrlParamsForYDH(
  imgUrl,
  hit,
  design,
  ydhCustomSettings,
  ydhGeneralSettings
) {
  let url = new URL(imgUrl);
  let options = filterUrlSearchParams(url);

  if (ydhCustomSettings.skip_defaults_removal) {
    // Some color image urls don't contain placement related parameters so we extract them from the category_images ixParams.
    // Otherwise we'd have bad image placements.
    let ixParams = {};
    if (hit?.category_images?.length > 0) {
      // Not all hits have category_images unfortunately. Find the first category_image with ix_params set.
      ixParams = hit?.category_images?.find(
        (obj) => Object.keys(obj?.ix_params || {}).length !== 0
      )?.ix_params;
    }
    options = {...ixParams, ...options};
  } else {
    // Remove the default placement options unless the custom YDH settings tell otherwise. This is the default behavior.
    delete options.placeMax;
    delete options.placeMaxPct;
    delete options.placeUseProduct;
    delete options.placeUseView;
  }

  // ydh default params < color default params < custom params (param precedence)
  options = {
    ...ydhGeneralSettings.defaultSettings,
    ...options,
    ...ydhCustomSettings
  };

  // Remove autonegate option if set by the custom YDH settings
  if (ydhCustomSettings.skip_autonegate) {
    options.autoNegate = 0;
  }

  removeNonUrlQueryParams(options, ydhGeneralSettings.nonUrlQueryParams);

  options.design = design;

  const params = new URLSearchParams(options);
  params.sort();
  url.search = params.toString();

  return url.toString();
}

// private

const getRequestDesign = () => {
  const req = new URL(window.location.href);
  const cid =
    req.searchParams.get('design') || req.searchParams.get('cid') || false;
  return cid;
};

const ensureDecoViz = (hit, url) => {
  // If the url already has a rendering effect specified (possibly due to the product's YDH overrides) don't attempt
  // to set the default values based on decoration type or material
  if (
    url.searchParams.get('imdebossmetal') ||
    url.searchParams.get('imdeboss') ||
    url.searchParams.get('imengravesteel') ||
    url.searchParams.get('imengravewood') ||
    url.searchParams.get('imengrave') ||
    url.searchParams.get('imembroider') ||
    url.searchParams.get('imemboss')
  ) {
    return url;
  }

  // Skip decoration visualization if set in YDH overrides
  if (url.searchParams.get('skip_deco_viz')) {
    url.searchParams.delete('skip_deco_viz');
    return url;
  }

  // embroidery effect
  if (hit.decoration_method === 'embroidery')
    url.searchParams.set('imembroider', '1');

  // laser engraved effect
  if (hit.decoration_method === 'laser engraved') {
    const surface = laserEngravingSurface(hit.material);
    if (surface === 'metal') url.searchParams.set('imengravesteel', '1');
    url.searchParams.set('autoNegate', '0');
  }

  // emboss effect
  if (hit.decoration_method === 'embossed') {
    url.searchParams.set('imemboss', '1');
    url.searchParams.set('autoNegate', '0');
    url.searchParams.set('ixq', '90');
  }

  // deboss effect
  if (hit.decoration_method === 'debossed') {
    url.searchParams.set('imdeboss', '1');
    url.searchParams.set('autoNegate', '0');
    url.searchParams.set('ixq', '90');
  }
  return url;
};

const setCustomDesignPreviewUrlParams = (
  customDesignPreview,
  url,
  colorLimit
) => {
  if (customDesignPreview) {
    let singleColorPreviewUrl = null;
    const designColorCount = customDesignPreviewColorCount(customDesignPreview);
    // colorLimit === 0 means there is no cap for the number of colors a product supports
    if (colorLimit !== 0 && designColorCount > colorLimit) {
      singleColorPreviewUrl =
        createSingleColorCustomDesignPreviewUrl(customDesignPreview);
    }

    // We can't override options.autoNegate because that'd result in permanent change of MMS image url params for a
    // particular style / blank color. When removing the design back to YDH, we'd have to set them back to the default
    // values provided in options of getColorImage and that's be too messy.
    // Only remove autonegate for products where we don't want to transform the design to single color due to product's
    // limited print capabilities (design has more colors than a product supports)
    if (!singleColorPreviewUrl) url.searchParams.set('autoNegate', '0');
    // Override placeMaxPct for drinkware (dr=1 is used for MMS Images to handle curvature of design preview) as otherwise,
    // it's set to 0.9 and that often results in the design being displayed as way too large.
    if (url.searchParams.get('dr') === '1')
      url.searchParams.set('placeMaxPct', '0.6');
    // Override design url param with custom design preview's url. Use single color url if necessary.
    if (singleColorPreviewUrl) {
      url.searchParams.set('design', singleColorPreviewUrl.toString());
    } else {
      url.searchParams.set('design', customDesignPreview?.url);
    }
  }
  return url;
};

const laserEngravingSurface = (material) => {
  const metalTokens = [
    'metal',
    'steel',
    'aluminum',
    'zinc',
    'brass',
    'insulation',
    'silicone',
    'pu fabric',
    'abs plastic',
    'ceramic'
  ];
  const woodTokens = ['wood', 'bamboo'];
  const mat = material.toLowerCase();
  let surface = '';
  metalTokens.forEach((token) => {
    if (mat.indexOf(token) >= 0) surface = 'metal';
  });
  woodTokens.forEach((token) => {
    if (mat.indexOf(token) >= 0) surface = 'wood';
  });
  return surface;
};

const isProd = () => {
  const env =
    document.querySelector('meta[property="rails_env"]')?.content || 'test';
  return env === 'production';
};

// Delete YDH config attributes we don't want to pass to MMS images but are used for other purposes (skip autonegate, etc.)
const removeNonUrlQueryParams = (options, nonUrlQueryParams) => {
  nonUrlQueryParams.forEach((key) => {
    delete options[key];
  });
  return options;
};

// By default returns all url search params as the default filter returns true to any value
export const filterUrlSearchParams = (
  stringUrl,
  filter = ([_key, _val]) => true
) => {
  const url = new URL(stringUrl);
  const entries = Array.from(url.searchParams.entries());
  return entries
    .filter(filter)
    .reduce((acc, [key, value]) => ({...acc, [key]: value}), {});
};

const hexColorDigits = (color = '') => {
  return color.replace('#', '');
};

const customDesignPreviewColorCount = (customDesignPreview) => {
  const colorCount = Object.keys(customDesignPreview.data.colors).length;
  if (
    customDesignPreview.data.background &&
    customDesignPreview.data.colors[customDesignPreview.data.background]
  ) {
    return colorCount - 1;
  } else return colorCount;
};

const createSingleColorCustomDesignPreviewUrl = (customDesignPreview) => {
  let previousUrlSearchParams = new URL(customDesignPreview?.url).searchParams;
  let singleColorPreviewUrl = new URL(
    getUploadManipulateUrl(
      customDesignPreview.data.url.replace('/uploads/', ''),
      `?${previousUrlSearchParams}`
    )
  );
  singleColorPreviewUrl.searchParams.set(
    'width',
    customDesignPreview.data.dimensions.width
  );
  singleColorPreviewUrl.searchParams.set('red', '0');
  singleColorPreviewUrl.searchParams.set('green', '0');
  singleColorPreviewUrl.searchParams.set('blue', '0');
  singleColorPreviewUrl.searchParams.set('contrast', '200');
  singleColorPreviewUrl.searchParams.set('autonegate', '1');
  return singleColorPreviewUrl;
};
